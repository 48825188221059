import {gql} from "@apollo/client";

const CANDIDATES_BY_PHONE = gql`
    query CANDDATES_BY_PHONE($phone: String = "") {
        candidates: candidates_employeecandidate(where: {phone: {_eq: $phone}}) {
            id
        }
    }
`;

export {
    CANDIDATES_BY_PHONE,
}