import React from "react";
import {Form, ProgressBar} from "react-bootstrap";
import {useTranslation} from "react-i18next";

export default React.memo((
  {
    label,
    loading = false,
    options,
    disabled = false,
    onChange,
    errors,
    isInvalid,
    value,
    name
  }
) => {
  const { t } = useTranslation()
  return (
    <Form.Group>
      {!!label && (<div><Form.Label>{label}</Form.Label></div>)}
      {loading ? (
        <ProgressBar variant='secondary' style={{height: '38px'}} label='Loading...' animated now={100}/>
      ) : (
        <>
          <Form.Select name={name} value={value} isInvalid={isInvalid} onChange={onChange} disabled={disabled}>
            <option selected value={null}>{t('control_buttons.not_selected')}</option>
            {options?.map((el) => (
              <option value={el.value}>{el.name}</option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
        </>
      )}
    </Form.Group>
)});
