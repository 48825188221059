import React, {useState, useCallback} from "react";
import * as yup from 'yup'
import axios from "axios";
import {Container, Row, Col, Card, Form, Stack, Button, Spinner, Alert, Image} from "react-bootstrap";
import {XCircle, CheckCircle} from "react-bootstrap-icons";
import {Formik} from "formik";
import {Result} from "../../components/Result";
import {useTranslation} from "react-i18next";
import "yup-phone-lite";
import {getAPIByCode} from "../../helpers/url";
import {useParams} from "react-router-dom";
import {toBase64} from "../../helpers/toBase64";
import SelectCountry from "../../components/SelectCountry";
import SelectCity from "../../components/SelectCity";
import {useLazyQuery} from "@apollo/client";
import {CANDIDATES_BY_PHONE} from "./query.gql";
import debounce from "../../helpers/debounce";
import banner from "../../assets/image/desires-banner.jpg"
import backgroundImage from "../../assets/image/desires-background.jpg"

export const ReserveForm = () => {
  const {t} = useTranslation()
  const [isLoading, setIsLodading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const params = useParams()

  const adminId = window.atob(params.b64id).split(',')[0]
  const countryCode = window.atob(params.b64id).split(',')[1]

  const [images, setImages] = useState([])

  const [getCandidates, {loading: candidatesLoading, data: candidatesData}] = useLazyQuery(CANDIDATES_BY_PHONE)

  const debouncedGetCandidates = useCallback(
    debounce((phone) => {
      getCandidates({variables: {phone}})
    }, 700)
    ,
    []
  );


  const validationSchema = yup.object().shape({
    desiredPlace: yup.string().required(t('form_validation.required')),
    desired_salary: yup.string(),
    name: yup
      .string()
      .required(t('form_validation.required'))
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        t('form_validation.latin_only')
      ),
    phone: yup
      .string()
      .phone([], t('form_validation.invalid_phone'))
      .required(t('form_validation.required')),
    email: yup
      .string()
      .email(t('form_validation.invalid_email')),
    link: yup
      .string()
      .required(t('form_validation.required')),
    city: yup
      .string()
      .required(t('form_validation.required')),
    terms: yup
      .boolean()
      .oneOf([true], t('form_validation.required')),
    file: yup
      .mixed()
      .required(t('form_validation.required')),
  })
  const initialValues = {
    desiredPlace: null,
    name: '',
    desired_salary: '',
    phone: '',
    email: '',
    link: '',
    country: null,
    city: null,
    wantWorldTattoo: false,
    wantWork: false,
    terms: false,
    file: null,
  }
  const loadImages = (files) => {
    const filesArray = Array.from(files); // Преобразовать объект files в массив
    Promise.all(
      filesArray.map(async (file) => ({
        name: file.name,
        type: file.type,
        extension: file.name.split('.')[file.name.split('.').length - 1],
        base64: await toBase64(file).then((res) => res),
      }))
    ).then((res) => {
      setImages(res);
    });
  }

  const submitForm = (formData) => {
    setIsLodading(true)
    const comment = `
      ${formData.wantWork ? 'Хочет получать предложения о работе' : ''}
      ${formData.wantWorldTattoo ? 'Хочет стать частью мировой тату-индустрии' : ''}
    `
    const data = {
      first_name: formData.name,
      last_name: '-',
      phone: formData.phone,
      desired_country: formData.desiredPlace,
      desired_salary: formData.desired_salary,
      social_media_link: formData.link,
      country: formData.country,
      city: formData.city,
      created_by: adminId || null,
      conversion_link: window.location.href,
      comment,
      images: images.map((el) => {
        return el.base64
      })
    }
    axios
      .post(
        `${getAPIByCode(countryCode)}/api/v1/candidates/employees/`,
        data
      )
      .then((response) => {
        setIsSuccess(true)
      })
      .catch(() => {
        setIsError(true)
      })
      .finally(() => {
        setIsLodading(false)
      })
  }

  return (
    <div style={{flex: 1, backgroundImage: `url(${backgroundImage})`, backgroundAttachment: 'fixed', backgroundSize: 'cover', backgroundPosition: 'center'}}>
      <Container className="py-5">
        <Row className="d-lg-none d-flex justify-content-md-center">
          <Col
            xs={12}
            lg={5}
          >
            <Image className='mb-3' src={banner} fluid rounded/>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col
            xs={12}
            lg={5}
          >
            <Alert style={{fontSize: '.8rem'}} className='art-inc-color-gold art-inc-bg-light-dark' variant='dark'>
              {t('alert.our_team')}
            </Alert>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col
            xs={12}
            lg={5}
          >
            {(!isSuccess && !isError) && <Card>
              <Card.Body>
                <Formik
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={submitForm}
                >
                  {({handleSubmit, touched, handleChange, errors, values}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Stack gap={3}>
                        <SelectCountry
                          label={t('desires.country_want')}
                          name="desiredPlace"
                          value={values.desiredPlace}
                          onChange={handleChange}
                          isInvalid={!!errors.desiredPlace && touched.desiredPlace}
                          errors={errors.desiredPlace}
                        />
                        <Form.Group>
                          <Form.Label>{t('desires.salary')}</Form.Label>
                          <Form.Control
                            name="desired_salary"
                            type="text"
                            value={values.desired_salary}
                            onChange={handleChange}
                            isInvalid={!!errors.desired_salary && touched.desired_salary}
                          />
                          <Form.Control.Feedback type="invalid">{errors.link}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('about.name')}</Form.Label>
                          <Form.Control
                            name="name"
                            type="text"
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name && touched.name}
                          />
                          <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('about.phone')}</Form.Label>
                          <Form.Control
                            name="phone"
                            type="tel"
                            value={values.phone}
                            onChange={(el) => {
                              handleChange(el);
                              debouncedGetCandidates(el.target.value)
                            }}
                            isInvalid={!!candidatesLoading || !!candidatesData?.candidates.length || !!errors.phone}
                          />
                          <Form.Text>
                            {!!candidatesLoading && t('form_validation.validating')}
                          </Form.Text>
                          <Form.Control.Feedback type="invalid">
                            {(!!candidatesData?.candidates.length && t('form_validation.phone_exist')) || errors.phone}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('about.email')}</Form.Label>
                          <Form.Control
                            name="email"
                            type="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email && touched.email}
                          />
                          <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('about.social_media_link')}</Form.Label>
                          <Form.Control
                            name="link"
                            type="text"
                            value={values.link}
                            onChange={handleChange}
                            isInvalid={!!errors.link && touched.link}
                          />
                          <Form.Control.Feedback type="invalid">{errors.link}</Form.Control.Feedback>
                        </Form.Group>
                        <SelectCountry
                          label={"*" + t('desires.country')}
                          name='country'
                          value={values.country}
                          onChange={handleChange}
                        />
                        <SelectCity
                          label={"*" + t('desires.city')}
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          isInvalid={!!errors.city && touched.city}
                          errors={errors.city}
                          country={values.country}
                        />
                        <Form.Group>
                          <Form.Label>{t('about.file')}</Form.Label>
                          <Form.Control
                            type="file"
                            multiple
                            name="file"
                            accept=".jpg, .png, .jpeg"
                            onChange={(e) => {
                              handleChange(e);
                              loadImages(e.target.files)
                            }}
                            isInvalid={!!errors.file && touched.file}
                            value={values.file}
                          />
                          <Form.Control.Feedback type="invalid">{errors.file}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                          <Form.Check
                            name="wantWorldTattoo"
                            onChange={handleChange}
                            checked={values.wantWorldTattoo}
                            label={t('about.want_world_tattoo')}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Check
                            name="wantWork"
                            onChange={handleChange}
                            checked={values.wantWork}
                            label={t('about.want_work')}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Check
                            label={t('about.terms')}
                            name="terms"
                            onChange={handleChange}
                            checked={values.terms}
                            isInvalid={!!errors.terms && touched.terms}
                          />
                        </Form.Group>
                        <Button className='art-inc-button-gold' disabled={isLoading} type='submit'>
                          {isLoading && <Spinner className='me-2' size='sm'/>}
                          <span>{t('control_buttons.submit')}</span>
                        </Button>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>}
            {isSuccess &&
              <Result
                icon={<CheckCircle/>}
                variant='Success'
                header={t('messages.success')}
              />
            }
            {isError &&
              <Result
                icon={<XCircle/>}
                variant='danger'
                header={t('messages.error')}
                message={t('messages.error_msg')}
              />
            }
          </Col>
        </Row>
      </Container>
    </div>
  )
}