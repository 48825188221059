const getAPIAddrPL = () => {
  return window.location.hostname === 'localhost' ? 'http://localhost:8000' : 'https://cr.vean-tattoo.pl'
}

const getAPIByCode = (code) => {
    return window.location.hostname === 'localhost' ? 'http://localhost:8000' : `https://cr${code === 'pl' ? '' : 'm'}.vean-tattoo.${code}`
}

const getHasuraDomain = () => {
  return window.location.hostname === 'localhost' ? 'http://localhost:8080/v1/graphql' : 'https://gq.vean-tattoo.pl/v1/graphql'
}


export {
  getAPIAddrPL,
  getHasuraDomain,
  getAPIByCode,
}