import React, {memo} from "react";
import {useCities} from "../hooks/locations";
import ExtendedSelect from "./ExtendedSelect";

export default memo(({label, name, value, onChange, errors, isInvalid, country}) => {
  const {citiesOptions, loading} = useCities(country)

  return (
    <ExtendedSelect
      label={label}
      name={name}
      value={value}
      options={citiesOptions}
      loading={loading}
      onChange={onChange}
      disabled={!citiesOptions.length}
      errors={errors}
      isInvalid={isInvalid}
    />
  )
})