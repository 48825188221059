import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import "toastify-js/src/toastify.css"
import App from './App';
import "./i18n";
import {Spinner} from "react-bootstrap";
import {store} from "./store";
import {ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Provider} from "react-redux";
import MetaPixel from "./components/MetaPixel";
import {getHasuraDomain} from "./helpers/url";

const client = new ApolloClient({
  uri: getHasuraDomain(),
  cache: new InMemoryCache(),
  headers: {
    'x-hasura-admin-secret': 'FgQkKg*8Qy',
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <MetaPixel/>
        <Suspense fallback={<Spinner animation="border" variant="secondary"/>}>
          <App/>
        </Suspense>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
);

