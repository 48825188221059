import React, {memo} from "react";
import ExtendedSelect from "./ExtendedSelect";
import {useCountries} from "../hooks/locations";

export default memo(({label, name, value, onChange, errors, isInvalid}) => {
  const {countriesOptions, loading} = useCountries();

  return (
    <ExtendedSelect
      label={label}
      name={name}
      value={value}
      options={countriesOptions}
      loading={loading}
      onChange={onChange}
      disabled={!countriesOptions.length}
      errors={errors}
      isInvalid={isInvalid}
    />
  )
})