import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";

const useCountries = () => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null)

  const countriesOptions = useMemo(() => {
    if (!countries.length)
      return []
    else
      return countries.map(el => ({
        name: el.name,
        value: el.name
      }))
  }, [countries])

  useEffect(() => {
    setLoading(true)
    axios.get('https://tattoolog.de/tools/countries/?page_size=400')
      .then(response => {
        setCountries(response.data.results);
      })
      .catch(error => {
        setError(error);
      })
      .finally(() => {
        setLoading(false)
      });
  }, []);


  return {countries, countriesOptions, loading, error};
};

const useStates = (country) => {
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null)

  useEffect(() => {
    if (country) {
      setLoading(true)
      axios.post(`https://countriesnow.space/api/v0.1/countries/states`, {
        country,
        order: "asc",
        orderBy: "name",
      })
        .then(response => {
          setStates(response.data.data.states);
        })
        .catch(error => {
          setError(error);
        })
        .finally(() => {
          setLoading(false)
        });
    }
  }, [country]);

  return {states, loading, error};
};

const useCities = (country) => {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null)

  const citiesOptions = useMemo(() => {
    if (!cities.length)
      return []
    else
      return cities.map(el => ({
        name: el.name,
        value: el.name
      }))
  }, [cities])

  useEffect(() => {
    if (country) {
      setLoading(true)
      axios.get(`https://tattoolog.de/tools/cities/?country=${country}&page_size=3000`)
        .then(response => {
          setCities(response.data.results);
        })
        .catch(error => {
          setError(error);
        })
        .finally(() => {
          setLoading(false)
        });
    }
  }, [country]);

  return {cities, citiesOptions, loading, error};
};

export {useCountries, useCities, useStates}
